<template>
  <div>
    <Breadcrumbs :items="crumbs" />
    
    <div class="white">
   
      <v-layout row wrap>
        <v-flex xs12 sm4>
          <router-link :to="{ name: 'wearables' }" class="nounder">
            <v-card flat>
              <v-img
                class="white--text"
                :aspect-ratio="1"
                :gradient="gradient"
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/3-min.png?alt=media&token=45e7958f-251e-4fa8-b8c6-2983c72b6504"
              >
              <v-layout pa-3 mt-0 column fill-height class="lightbox white--text">
                
                  <v-flex shrink>
                    <h3 class="display-1 mb-0">Wearables</h3>
                  </v-flex>
                </v-layout>
              </v-img>
            </v-card>
          </router-link>
        </v-flex>

        <v-flex
          xs6
          sm4
        >
          <router-link :to="{ name: 'fan' }" class="nounder">
            <v-card flat>
              <v-img
                class="white--text"
                :aspect-ratio="1"
                :gradient="gradient"
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/ATT_Park_San_Francisco_baseball_stadium-1920x1080-min.jpg?alt=media&token=7cfd45b4-2922-4c97-818d-1b56ec8d300f"
              >
              <v-layout pa-3 mt-0 column fill-height class="lightbox white--text">
               
                  <v-flex shrink>
                    <h3 class="display-1 mb-0">Fan Experiences</h3>
                  </v-flex>
                </v-layout>
              </v-img>
            </v-card>
          </router-link>
        </v-flex>
        <v-flex
          xs6
          sm4
        >
          <router-link :to="{ name: 'consumer-goods' }" class="nounder">
            <v-card flat>
              <v-img
                class="white--text"
                :aspect-ratio="1"
                :gradient="gradient"
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/viizzii-birthday.jpg?alt=media&token=462913ef-ee3c-4c8e-af12-738072a64b12"
              >
              <v-layout pa-3 mt-0 column fill-height class="lightbox white--text">
                
                  <v-flex shrink>
                    <h3 class="display-1 mb-0">Consumer Goods</h3>
                  </v-flex>
                </v-layout>
              </v-img>
            </v-card>
          </router-link>
        </v-flex>

        <v-flex
          xs6
          sm4
        >
          <router-link :to="{ name: 'beverage-licensing' }" class="nounder">
            <v-card flat>
              <v-img
                class="white--text"
                :aspect-ratio="1"
                :gradient="gradient"
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/Holiday%20copy-min.jpg?alt=media&token=070e9224-2ac5-49b4-bf7a-2b451d0df858"
              >
              <v-layout pa-3 mt-0 column fill-height class="lightbox white--text">
                
                  <v-flex shrink>
                    <h3 class="display-1 mb-0">Beverage Licensing</h3>
                  </v-flex>
                </v-layout>
              </v-img>
            </v-card>
          </router-link>
        </v-flex>

        <v-flex xs6 sm4>
          <router-link :to="{ name: 'logistics' }" class="nounder">
            <v-card flat>
              <v-img
                class="white--text"
                :aspect-ratio="1"
                :gradient="gradient"
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/amazon-fall-river-fulfillment-center-17.jpg?alt=media&token=76dc65d7-1255-49bc-b140-7ee40ad5993c"
              >
              <v-layout pa-3 mt-0 column fill-height class="lightbox white--text">
                
                  <v-flex shrink>
                    <h3 class="display-1 mb-0">Logistics</h3>
                  </v-flex>
                </v-layout>
              </v-img>
            </v-card>
          </router-link>
        </v-flex>

        <v-flex xs6 sm4>
          <router-link :to="{ name: 'conventions' }" class="nounder">
            <v-card flat>
              <v-img
                class="white--text"
                :aspect-ratio="1"
                :gradient="gradient"
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/convention.jpg?alt=media&token=348e4d6d-6f1c-447f-ae86-2a83ac7c6683"
              >
              <v-layout pa-3 mt-0 column fill-height class="lightbox white--text">
                
                  <v-flex shrink>
                    <h3 class="display-1 mb-0">Conventions</h3>
                  </v-flex>
                </v-layout>
              </v-img>
            </v-card>
          </router-link>
        </v-flex>

        <v-flex xs6 sm4>
          <router-link :to="{ name: 'advertising' }" class="nounder">
            <v-card flat>
              <v-img
                class="white--text"
                :aspect-ratio="1"
                :gradient="gradient"
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/truck.jpg?alt=media&token=04598668-02ac-42a4-9998-c2b0481da596"
              >
              <v-layout pa-3 mt-0 column fill-height class="lightbox white--text">
                
                  <v-flex shrink>
                    <h3 class="display-1 mb-0">Advertising</h3>
                  </v-flex>
                </v-layout>
              </v-img>
            </v-card>
          </router-link>
        </v-flex>

        <v-flex xs6 sm4>
          <router-link :to="{ name: 'retail' }" class="nounder">
            <v-card flat>
              <v-img
                class="white--text"
                :aspect-ratio="1"
                :gradient="gradient"
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/shopping.jpg?alt=media&token=5db83601-892a-4c45-8ae6-c4a100454e8a"
              >
              <v-layout pa-3 mt-0 column fill-height class="lightbox white--text">
                
                  <v-flex shrink>
                    <h3 class="display-1 mb-0">Retail</h3>
                  </v-flex>
                </v-layout>
              </v-img>
            </v-card>
          </router-link>
        </v-flex>

        <v-flex xs6 sm4>
          <router-link :to="{ name: 'healthcare' }" class="nounder">
            <v-card flat>
              <v-img
                class="white--text"
                :aspect-ratio="1"
                :gradient="gradient"
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/doc.jpg?alt=media&token=92042c92-8cea-4681-885a-9edc96018e71"
              >
              <v-layout pa-3 mt-0 column fill-height class="lightbox white--text">
                
                  <v-flex shrink>
                    <h3 class="display-1 mb-0">Healthcare</h3>
                  </v-flex>
                </v-layout>
              </v-img>
            </v-card>
          </router-link>
        </v-flex>

        
      </v-layout>
    </div>
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  data() {
    return {
      name: this.$route.name,
      gradient: "to top right, rgba(0,0,0,.1), rgba(0,0,0,.1)",
      crumbs: [
        {
          text: 'Home',
          disabled: false,
          exact: true,
          to: '/'
        },
        {
          text: 'Industries',
          disabled: true,
          to: ''
        }
      ],
    }
  },
  components: {
    Nav,
    Footer,
    Breadcrumbs
  },
}
</script>